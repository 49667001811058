"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _differenceInCalendarDays = _interopRequireDefault(require("date-fns/differenceInCalendarDays"));
var _startOfWeek = _interopRequireDefault(require("date-fns/startOfWeek"));
var _min = _interopRequireDefault(require("date-fns/min"));
var _max = _interopRequireDefault(require("date-fns/max"));
var _lodash = _interopRequireDefault(require("lodash.omit"));
var _lodash2 = _interopRequireDefault(require("lodash.pick"));
var _validInterval = _interopRequireDefault(require("@oberoninternal/travelbase-ds/utils/validInterval"));
var _router = require("next/router");
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _activitySearchParams = _interopRequireDefault(require("../constants/activitySearchParams"));
var _weekday = require("../utils/weekday");
var _eachDayOfInterval = _interopRequireDefault(require("date-fns/eachDayOfInterval"));
var _format = _interopRequireDefault(require("date-fns/format"));
var _createImgProps = _interopRequireDefault(require("../utils/createImgProps"));
var _getBestTimeslot = _interopRequireDefault(require("../utils/getBestTimeslot"));
var _ActivityTile = _interopRequireDefault(require("./ActivityTile"));
var _isSameDay = _interopRequireDefault(require("date-fns/isSameDay"));
var _useQueryParams = require("use-query-params");
var _ActivityPlanner = require("./ActivityPlanner");
var _parseDateTime = _interopRequireDefault(require("../utils/parseDateTime"));
var _TenantContext = require("../context/TenantContext");
var _FavoriteIconButton = _interopRequireDefault(require("./FavoriteIconButton"));
var _useGetPageSlugByLocale = _interopRequireDefault(require("../hooks/useGetPageSlugByLocale"));
var _getActivityTileState = _interopRequireDefault(require("../utils/getActivityTileState"));
const _excluded = ["hit", "sizes", "dontPassDate"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const hourMessages = (0, _reactIntl.defineMessages)({
  hour: {
    "id": "activityHour",
    "defaultMessage": [{
      "type": 0,
      "value": "uur"
    }]
  }
});
const getDateRanges = (timeSlots, locale) => {
  const ranges = [];
  const dates = timeSlots.map(timeSlot => (0, _parseDateTime.default)(timeSlot.startDateTime));
  if (!dates.length) {
    return [];
  }
  const minDate = (0, _min.default)(dates);
  const maxDate = (0, _max.default)(dates);
  if (!(0, _validInterval.default)({
    start: minDate,
    end: maxDate
  })) {
    return [];
  }
  const datesOfInterval = (0, _eachDayOfInterval.default)({
    start: minDate,
    end: maxDate
  }).filter(day => dates?.some(date => (0, _isSameDay.default)(date, day)));
  const uniqueSortedDays = datesOfInterval.sort((a, b) => {
    const dateA = (0, _format.default)(a, 'EEEE');
    const dateB = (0, _format.default)(b, 'EEEE');
    return _weekday.Weekdays.indexOf(dateA.toLowerCase()) - _weekday.Weekdays.indexOf(dateB.toLowerCase());
  });
  for (let i = 0; i < uniqueSortedDays.length; i += 1) {
    const startDay = uniqueSortedDays[i];
    for (let j = i; j < uniqueSortedDays.length; j += 1) {
      const currentDay = uniqueSortedDays[j];
      const nextDay = uniqueSortedDays[j + 1] ? uniqueSortedDays[j + 1] : currentDay;
      const currentDayOfWeek = (0, _differenceInCalendarDays.default)(currentDay, (0, _startOfWeek.default)(currentDay, {
        weekStartsOn: 1
      }));
      const lastTimeSlotOfWeek = (0, _differenceInCalendarDays.default)(uniqueSortedDays[uniqueSortedDays.length - 1], (0, _startOfWeek.default)(currentDay, {
        weekStartsOn: 1
      }));
      const nextDayOfWeek = (0, _differenceInCalendarDays.default)(nextDay, (0, _startOfWeek.default)(nextDay, {
        weekStartsOn: 1
      }));
      if (nextDayOfWeek - currentDayOfWeek > 1 || currentDayOfWeek === lastTimeSlotOfWeek) {
        ranges.push({
          start: startDay.toLocaleString(locale, {
            weekday: 'short'
          }),
          end: currentDay.toLocaleString(locale, {
            weekday: 'short'
          })
        });
        i = j;
        break;
      }
    }
  }
  const uniqueRanges = [...new Set(ranges.map(range => JSON.stringify(range)))].map(range => JSON.parse(range));
  return uniqueRanges;
};
const ActivityHit = _ref => {
  let {
      hit,
      sizes,
      dontPassDate
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  const {
    timeslots
  } = hit;
  const {
    brandConfig
  } = (0, _TenantContext.useTenantContext)();
  const bestTimeslot = (0, _getBestTimeslot.default)(timeslots, hit.bookingType);
  const [params] = (0, _useQueryParams.useQueryParams)(_activitySearchParams.default);
  const intl = (0, _reactIntl.useIntl)();
  const {
    getPageSlugByLocale
  } = (0, _useGetPageSlugByLocale.default)();
  const activityPageSlug = getPageSlugByLocale('ACTIVITY');
  const router = (0, _router.useRouter)();
  const {
    locale
  } = (0, _reactIntl.useIntl)();
  const startDate = (0, _react.useMemo)(() => params.startDate && new Date(params.startDate), [params.startDate]);
  const endDate = (0, _react.useMemo)(() => params.endDate && new Date(params.endDate), [params.endDate]);
  const isSingleDay = !!(startDate && endDate && (0, _isSameDay.default)(startDate, endDate));
  const isToday = isSingleDay && startDate && (0, _isSameDay.default)(new Date(), startDate);
  const isFutureSingleDay = startDate && endDate && !isToday && (0, _isSameDay.default)(startDate, endDate);
  const isRange = !isSingleDay;
  const ranges = (0, _react.useMemo)(() => getDateRanges(timeslots, locale), [timeslots, locale]);
  const upcomingTimeslots = timeslots?.filter(timeslot => (0, _ActivityPlanner.isUpcoming)(timeslot));
  const hasTimeSlots = upcomingTimeslots.length > 0;
  const timeSlotChildren = (0, _react.useMemo)(() => __jsx(_react.default.Fragment, null, (isToday || isFutureSingleDay) && hasTimeSlots && __jsx("strong", null, isToday && __jsx(_react.default.Fragment, null, __jsx(_reactIntl.FormattedMessage, {
    id: "qaGPK1",
    defaultMessage: [{
      "type": 0,
      "value": "Vandaag, vanaf"
    }]
  }), "\xA0"), [...upcomingTimeslots]?.sort((timeslotA, timeslotB) => (0, _parseDateTime.default)(timeslotA.startDateTime).valueOf() - (0, _parseDateTime.default)(timeslotB.startDateTime).valueOf())?.map((timeSlot, index) => __jsx(_react.default.Fragment, {
    key: timeSlot.id
  }, (0, _format.default)((0, _parseDateTime.default)(timeSlot.startDateTime), 'HH:mm'), ' ', intl.formatMessage(hourMessages.hour), index !== upcomingTimeslots.length - 1 && __jsx(_react.default.Fragment, null, "\xA0\xB7\xA0")))), isRange && hasTimeSlots && __jsx("strong", null, ranges.map((range, index) => __jsx(_react.default.Fragment, {
    key: range.start
  }, range.start !== range.end ? `${range.start} - ${range.end}` : range.end, index !== ranges.length - 1 && __jsx(_react.default.Fragment, null, "\xA0\xB7\xA0"))))), [hasTimeSlots, intl, isFutureSingleDay, isRange, isToday, ranges, upcomingTimeslots]);
  return __jsx(_ActivityTile.default, (0, _extends2.default)({
    title: hit.name,
    videoUrl: hit.videoUrl,
    place: hit.place,
    timeSlotChildren: timeSlotChildren,
    themes: hit.themes.map(t => t.name).join(', ') ?? undefined,
    icon: brandConfig.enableFavorites && __jsx(_FavoriteIconButton.default, {
      type: "activity",
      id: hit.id
    }),
    linkProps: {
      href: {
        pathname: `/${activityPageSlug}/${hit.slug}`,
        query: (0, _lodash2.default)((0, _lodash.default)(router.query, ['view', ...(dontPassDate ? ['date'] : [])]), Object.keys(_activitySearchParams.default))
      },
      locale: intl.locale
    },
    fullUrl: `${typeof window !== 'undefined' && window.location.origin}/${activityPageSlug}/${hit.slug}`,
    img: hit.listImage && hit.webpListImage ? (0, _createImgProps.default)(hit.listImage, hit.webpListImage, sizes) : undefined,
    activityState: (0, _getActivityTileState.default)(hit),
    prices: bestTimeslot?.rateGroup?.rates.map(rate => ({
      name: rate.name,
      price: rate.price
    }))
  }, props));
};
var _default = exports.default = ActivityHit;