"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ActivityPlanner = require("../components/ActivityPlanner");
var _sortBy = _interopRequireDefault(require("lodash/sortBy"));
const getBestRate = timeslot => Math.min(...(timeslot.rateGroup?.rates.map(rate => rate.price) ?? []));
const getBestTimeslot = (timeslots, bookingType) => {
  const bestTimeslot = (0, _sortBy.default)(timeslots.filter(slot => (0, _ActivityPlanner.isUpcoming)(slot, true)), slot => getBestRate(slot), slot => (0, _ActivityPlanner.ticketsAvailable)(slot, bookingType) ? 0 : 1)[0];
  return bestTimeslot;
};
var _default = exports.default = getBestTimeslot;