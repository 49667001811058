"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.activitySearchViewParams = exports.activitySearchFilterParams = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _useQueryParams = require("use-query-params");
var _ArrayParam = _interopRequireDefault(require("../utils/ArrayParam"));
var _DateParam = _interopRequireDefault(require("../utils/DateParam"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
const activitySearchFilterParams = exports.activitySearchFilterParams = {
  placeId: _useQueryParams.StringParam,
  themeSlugs: _ArrayParam.default,
  targetAudiences: _ArrayParam.default,
  companyId: _useQueryParams.StringParam
};
const activitySearchViewParams = exports.activitySearchViewParams = {
  startDate: _DateParam.default,
  endDate: _DateParam.default,
  view: _useQueryParams.StringParam
};
const activitySearchParams = _objectSpread(_objectSpread({}, activitySearchFilterParams), activitySearchViewParams);
var _default = exports.default = activitySearchParams;