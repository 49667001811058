"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.canBuyTickets = canBuyTickets;
exports.default = void 0;
var _graphql = require("../generated/graphql");
const getActivityState = hit => {
  const {
    timeslots
  } = hit;
  const canBuy = timeslots.length > 0 && timeslots.some(timeslot => canBuyTickets(hit.bookingType === _graphql.ActivityBookingTypesEnum.Direct, hit.bookingType === _graphql.ActivityBookingTypesEnum.External, timeslot.allotment ?? undefined));
  if (canBuy && timeslots.some(timeslot => timeslot.allotment !== 0)) {
    return 'AVAILABLE';
  }
  if (hit.bookingType === _graphql.ActivityBookingTypesEnum.External || hit.bookingType === _graphql.ActivityBookingTypesEnum.NotBookable) {
    return 'EXTERAL';
  }
  if (hit.bookingType === _graphql.ActivityBookingTypesEnum.Direct && timeslots.every(timeslot => timeslot.allotment === 0)) {
    return 'SOLD_OUT';
  }
  return 'UNAVAILABLE';
};
function canBuyTickets(hasOnlineSale, hasOfflineSale, allotment) {
  const hasAllotment = allotment !== 0; // undefined means "infinite" allotment
  // when there's offline sale, you can always buy offline. when there is online sale there needs to be positive allotment
  return hasOnlineSale && hasAllotment || hasOfflineSale;
}
var _default = exports.default = getActivityState;