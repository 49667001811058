"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.openMessage = exports.default = exports.alwaysOpenMessage = void 0;
var _endOfDay = _interopRequireDefault(require("date-fns/endOfDay"));
var _isWithinInterval = _interopRequireDefault(require("date-fns/isWithinInterval"));
var _startOfDay = _interopRequireDefault(require("date-fns/startOfDay"));
var _reactIntl = require("react-intl");
var _weekday = require("./weekday");
const timeStrToMinutes = time => {
  const [hours, minutes] = time.split(':');
  return Number(hours) * 60 + Number(minutes);
};
const getRelevantDayTime = dayOpeningTimes => {
  const curDate = new Date();
  const curTimeInMin = timeStrToMinutes(`${curDate.getHours()}:${curDate.getMinutes()}`);
  const closedTimesInMin = dayOpeningTimes.map(t => timeStrToMinutes(t.timeClosed));
  let lastTimeIndex = closedTimesInMin.length - 1;
  if (closedTimesInMin.length > 1) {
    closedTimesInMin.some((t, i) => {
      if (curTimeInMin < t) {
        lastTimeIndex = i;
        return true;
      }
      return false;
    });
  }
  return dayOpeningTimes[lastTimeIndex];
};
const openMessage = exports.openMessage = (0, _reactIntl.defineMessage)({
  "id": "UwURcz",
  "defaultMessage": [{
    "type": 0,
    "value": "Open van "
  }, {
    "type": 1,
    "value": "open"
  }, {
    "type": 0,
    "value": " tot "
  }, {
    "type": 1,
    "value": "close"
  }]
});
const alwaysOpenMessage = exports.alwaysOpenMessage = (0, _reactIntl.defineMessage)({
  "id": "3NzZaS",
  "defaultMessage": [{
    "type": 0,
    "value": "Altijd open"
  }]
});

/**
 * This function returns the relevant time for a given date.
 *
 * Some examples:
 * If there are special opening hours for the given date, it will return the special opening hours.
 * If there are no special opening hours, it will return the normal opening hours.
 * If there are no normal opening hours, it will return 'Gesloten'.
 * If the opening hours are 00:00 - 23:59, it will return 'Altijd open'.
 *
 * @param intl - The intl object from react-intl
 * @param weekTimes - The normal opening hours
 * @param specialOpeningHours - The special opening hours
 * @param date - The date for which you want to get the relevant time
 * @returns - The relevant time for the given date
 */
const getRelevantTime = (intl, weekTimes, specialOpeningHours, date = new Date()) => {
  const curDate = date;
  const day = (0, _weekday.getWeekday)(date);
  if (specialOpeningHours) {
    for (const s of specialOpeningHours) {
      const startDate = (0, _startOfDay.default)(new Date(s.startDate));
      const endDate = (0, _endOfDay.default)(new Date(s.endDate));
      const sohToday = s[day];
      if (sohToday && (0, _isWithinInterval.default)(curDate, {
        start: startDate,
        end: endDate
      })) {
        const timeOpen = getRelevantDayTime(sohToday)?.timeOpen;
        const timeClosed = getRelevantDayTime(sohToday)?.timeClosed;
        if (timeOpen === '00:00' && timeClosed === '23:59') {
          return intl.formatMessage(alwaysOpenMessage);
        }
        return sohToday.length > 0 ? intl.formatMessage(openMessage, {
          open: timeOpen,
          close: timeClosed
        }) : intl.formatMessage({
          "id": "XLCwGj",
          "defaultMessage": [{
            "type": 0,
            "value": "Gesloten"
          }]
        });
      }
    }
  }
  if (weekTimes) {
    const wtToday = weekTimes[day];
    if (wtToday && wtToday.length > 0) {
      const {
        timeOpen
      } = getRelevantDayTime(wtToday);
      const {
        timeClosed
      } = getRelevantDayTime(wtToday);
      if (timeOpen === '00:00' && timeClosed === '23:59') {
        return intl.formatMessage(alwaysOpenMessage);
      }
      return intl.formatMessage(openMessage, {
        open: timeOpen,
        close: timeClosed
      });
    }
  }
  return intl.formatMessage({
    "id": "XLCwGj",
    "defaultMessage": [{
      "type": 0,
      "value": "Gesloten"
    }]
  });
};
var _default = exports.default = getRelevantTime;